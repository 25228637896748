import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AxiosError } from "axios";
import { Fieldset } from "primereact/fieldset";
import { Message } from "primereact/message";
import { Chart } from "primereact/chart";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { setBreadcrumb } from "../../redux/actions/breadcrumb-actions";
import ApiService from "../../api-service";
import { AxiosToastError } from "../../utils/AxiosToastError";
import { DateUtils } from "../../utils/DateUtils";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { MeterGroup } from 'primereact/metergroup';
import { useMediaQuery } from "react-responsive";

interface HistoricalRevisionsReportPageProps {

}

enum PlateReportEnum {
    SUCCESS = "SUCCESS",
    FAILED_EXTERNAL_API = "FAILED_EXTERNAL_API",
    FAILED_GPT = "FAILED_GPT",
    FAILED_GENERIC = "FAILED_GENERIC"
}

export const HistoricalRevisionsReportPage: FC<HistoricalRevisionsReportPageProps> = ({ }) => {
    const dispatch = useDispatch();
    const [laoding, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<any[]>([]);
    const [selectedReport, setSelectedReport] = useState<any>({});
    const [viewModal, setViewModal] = useState<boolean>(false);

    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });
    
    useEffect(() => {
        if (!isMobile) {
            dispatch(
                setBreadcrumb([
                    {
                        id: "1", label: "Dashboard",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/dashboard'}>Dashboard</Link>
                    },
                    {
                        id: "2", label: "Admin",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/plate'}>Admin</Link>
                    },
                    {
                        id: "3", label: "Reports",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/historical-revisions'}>Reports</Link>
                    },
                    {
                        id: "3",
                        label: "Storico Revisioni",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/historical-revisions'}>Storico Revisioni</Link>
                    }
                ])
            );
        } else {
            dispatch(
                setBreadcrumb([
                    { id: "2", label: "Admin", template: () => <Link to='/admin/'><i className="pi pi-lock"></i></Link> },
                    { id: "3", label: "Reports", template: () => <Link to='/admin/reports/historical-revisions'><i className="pi pi-list"></i></Link> },
                    { id: "4", label: "Storico RevisionI", template: () => <Link to='/admin/reports/historical-revisions'><i className="pi pi-chart-pie"></i></Link> }
                ])
            );
        }

    }, [isMobile])

    useEffect(() => {
        setLoading(true);
        ApiService.getHistoricalRevisionsReports().then(res => {
            let values: any[] = []
            res.data.forEach((r: any) => {
                r.date = DateUtils.timestampFormat(r.date)
                values.push(r)
            })

            setReports(values)
            setLoading(false);
        }).catch((err: AxiosError) => {
            setLoading(false);
            AxiosToastError.showError(err, dispatch)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(selectedReport).length > 0) {
            setViewModal(true);
        }
    }, [selectedReport])

    const badgeBodyTemplate = (rowData: any) => {
        if (rowData.status === "SUCCESS") {
            return <Badge severity="success" value="SUCCESS"></Badge>
        } else if (rowData.status === "FAILED") {
            return <Badge severity="danger" value="FAILED"></Badge>
        } else {
            return <Badge severity="info" value={rowData.status}></Badge>
        }
    }

    const calcolaPercentuale = (numero: number) => {
        let percentuale = (numero / reports.length) * 100;
        return Math.round(percentuale);
    }

    const meterGroupValues = [
        { label: "SUCCESS", color: '#34d399', value: calcolaPercentuale(reports.filter(r => r.status === "SUCCESS").length) },
        { label: "FAILED", color: '#d32f2f', value: calcolaPercentuale(reports.filter(r => r.status === "FAILED").length) },
    ];

    return (
        <div>
            <Dialog header="Stack Trace Error" visible={viewModal} maximizable style={{ width: '50vw', backgroundColor: "var(--gray-900)" }} onHide={() => setViewModal(false)}>
                <p className="m-0">
                    {selectedReport.errorStackTrace}
                </p>
            </Dialog>

            <h3>Numero di report raccolti: <b>{reports.length}</b></h3>

            <div style={{
                paddingTop: 5,
                paddingBottom: 15,
                display: "flex",
                flexDirection: "column"
            }} className="card flex justify-content-center">
                <MeterGroup values={meterGroupValues} />
            </div>

            <DataTable loading={laoding} stripedRows removableSort sortField="id" sortOrder={-1} value={reports} paginator rows={5} tableStyle={{ minWidth: '50rem' }}
                onSelectionChange={(e) => setSelectedReport(e.value)}
                selectionMode="single"
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}">
                <Column field="plate" header="Targa" sortable style={{ width: '5%' }}></Column>
                <Column body={badgeBodyTemplate} field="status" header="Status" sortable style={{ width: '5%' }}></Column>
                <Column field="date" header="Data" sortable style={{ width: '10%' }}></Column>
                <Column field="errorMessage" header="Errore" style={{ width: '25%' }}></Column>
            </DataTable>
        </div>
    )
}