// Esempio di un servizio per le chiamate REST
import axios from 'axios';
import { config } from '../constants';

interface AuthData {
    email: string
    password: string
}

interface RefeshTokenData {
    refreshToken: string
}

interface CarInfo {
    plate: string,
    full_info?: boolean
}

interface Pagination {
    offset: number,
    limit: number
}

interface ResetPassword {
    email: string,
    otp: string,
    password: string
}

interface UserInfo {
    name: string,
    surname: string
}

const apiInstance = axios.create({
    baseURL: config.backEndBaseURL,
});

apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

apiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if(error.response && error.response.status === 401) {
       if(error.request.responseURL === `${config.backEndBaseURL}/login`){
            return Promise.reject(error);
       }
        
        ApiService.refreshToken({ refreshToken: localStorage.getItem('refreshToken') || '' }).then((res) => {
            localStorage.setItem('accessToken', res.data.access_token);
            localStorage.setItem('refreshToken', res.data.refresh_token);
            window.location.reload();
        }).catch((err) => {
            window.location.href = '/login';
        })
      }

      return Promise.reject(error);
    }
  );

const ApiService = {
    // Auth
    login: (data: AuthData) => apiInstance.post('/login', data),
    registration: (data: AuthData) => apiInstance.post('/registration', data),
    refreshToken: (data: RefeshTokenData) => apiInstance.post('/refresh-token', data),

    getCarInfo: (data: CarInfo) => apiInstance.get(`/plate-info/auto?plate=${data.plate}&full_info=${data.full_info}`),
    getPreviewPlate: (q: string) => apiInstance.get(`/preview-plate?q=${q}`),
    getList: (pagination?: Pagination, filter?: string) => {
        return apiInstance.get(pagination ? `/plate-list?offset=${pagination.offset}&limit=${pagination.limit}&filter=${filter || ''}` : `/plate-list?filter=${filter || ""}`)
    },
    getHistoricalRevisions: (plate: string) => apiInstance.get(`/api/storico-revisioni?plate=${plate}`),

    getReportsAuth: () => apiInstance.get(`/api/v1/reports/auth`),
    getReportsMobile: () => apiInstance.get(`/api/v1/reports/mobile-reports`),
    getReportsPlate: () => apiInstance.get(`/api/v1/reports/plate-reports`),
    getUsers: () => apiInstance.get(`/api/v1/reports/users`),
    getHistoricalRevisionsReports: () => apiInstance.get(`/api/v1/reports/historical-revisions`),

    resetPassword: (email: string) => apiInstance.get(`/user/reset-password?email=${email}`),
    handleResetPassword: (dto: ResetPassword) => apiInstance.post(`/user/reset-password/${dto.email}`, {
        otp: dto.otp,
        password: dto.password
    }),
    getUserCredit: () => apiInstance.get(`/user/credit`),
    getPrivacy: () => apiInstance.get(`/privacy`, { responseType: 'blob' }),
    updateUserInfo: (userInfo: UserInfo) => apiInstance.post(`/user/update-user-info`, userInfo), 
};

export default ApiService;
