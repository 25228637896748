import axios from "axios";
import { Button } from "primereact/button";
import { v4 as uuidv4 } from 'uuid';
import { InputText } from "primereact/inputtext";
import { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";

interface ChatbotProps { }

enum ChatbotMessageType {
    CHATBOT,
    USER
}

interface ChatbotChatMessage {
    type: ChatbotMessageType,
    message: string,
    error?: boolean
}

const generaStringaCasuale = (lunghezza: number): string => {
    let result = '';
    for (let i = 0; i < lunghezza; i++) {
        result += Math.floor(Math.random() * 10).toString();
    }
    return result;
}

const Chatbot: React.FC<ChatbotProps> = () => {
    const [isOpened, setIsOpened] = useState(false);
    const [messages, setMessages] = useState<ChatbotChatMessage[]>([]);
    const [conversationId] = useState<string>(uuidv4());
    const [id] = useState<string>(generaStringaCasuale("7415991692010340290".length));
    const [messageUser, setMessageUser] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const chatBodyRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        messages.push({ type: ChatbotMessageType.CHATBOT, message: "Ciao, come posso aiutarti?" });
    }, [])

    // Scroll to bottom whenever messages change
    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    async function makeRequest(content: string) {
        try {
            setIsLoading(true); // Start loading
            const messagesToSend: any[] = [];
            messages.push({ type: ChatbotMessageType.USER, message: content });
            messages.forEach(message => {
                messagesToSend.push({ role: message.type === ChatbotMessageType.CHATBOT ? "assistant" : "user", content: message.message });
            });
            const response = await axios.post(
                'https://gpt-fe.targa.site/backend-api/v2/conversation',
                {
                    id: id,
                    conversation_id: conversationId,
                    model: "",
                    web_search: false,
                    provider: "",
                    messages: [
                        ...[{ role: "user", content: "Rispondi solamente a domande riguardanti l'automotive" }],
                        ...messagesToSend
                    ],
                    auto_continue: true,
                    api_key: null
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    responseType: 'json'
                }
            );

            let responseMsg = "";
            response.data.split("\n").forEach((json: string) => {
                try {
                    const message = JSON.parse(json);
                    console.log(message);

                    if (message.type === "content") {
                        responseMsg += message.content;
                    }
                } catch (error) {
                    // Niente
                }
            });
            console.log(responseMsg);

            messages.push({ type: ChatbotMessageType.CHATBOT, message: responseMsg });
        } catch (error) {
            console.error('Error sending message:', error);
            messages.push({ type: ChatbotMessageType.CHATBOT, message: "Errore durante l'invio del messaggio", error: true });
        } finally {
            setIsLoading(false); // End loading
        }
        setMessages([...messages]);
    }

    const handleInputChatUser = (event: any) => {
        if (event.key === 'Enter') {
            makeRequest(event.currentTarget.value);
            setMessages([...messages]);

            // Reset input
            event.currentTarget.value = "";
        }
    }

    const handleSendChatUser = () => {
        makeRequest(messageUser);
        setMessages([...messages]);
    }

    return <div style={!isMobile ? {
        position: 'fixed',
        zIndex: 9999,
        bottom: '0',
        right: '0',
        marginBottom: '20px',
        marginRight: '20px'
    } : {
        position: 'fixed',
        zIndex: 9999,
        bottom: '0',
        right: '0',
        padding: 10
    }}>
        {isOpened ? <div style={{
            width: '400px',
            height: '500px',
            borderRadius: '10px',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div className="chatbot-header" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 8,
                backgroundColor: "#7254f3",
                borderRadius: '10px 10px 0 0',
                boxShadow: '5px 5px 10px #00000040',
            }}>
                <span style={{ fontSize: '20px', fontWeight: 'bold', margin: '10px' }}>Chatbot</span>
                <Button severity="secondary" icon="pi pi-times" text onClick={() => setIsOpened(false)} />
            </div>
            <div ref={chatBodyRef} className="chatbot-body" style={{ height: "100%", display: "flex", flexDirection: "column", gap: 8, marginTop: 12, overflowY: "auto" }}>
                {messages.map((message, index) => (
                    message.type === ChatbotMessageType.CHATBOT ?
                        <div key={index} style={{ display: "flex", justifyContent: "start", }}>
                            <div key={index} style={{ backgroundColor: message.error ? "var(--pink-300)" : "var(--surface-500)", padding: 10, margin: 10, borderRadius: 10 }}>
                                <label style={{ color: message.error ? "red" : "black" }}>{message.message}</label>
                            </div>
                        </div> :
                        <div key={index} style={{ display: "flex", justifyContent: "end", }}>
                            <div style={{ padding: 10, margin: 10, backgroundColor: "var(--text-color-secondary)", borderRadius: 10 }}>
                                <label style={{ color: "white" }}>{message.message}</label>
                            </div>
                        </div>
                ))}
                {isLoading && (
                    <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
                        <label>Processing...</label>
                    </div>
                )}
            </div>
            <div className="chatbot-footer" style={{
                boxShadow: '-5px -5px 10px #00000040',
                display: "flex"
            }}>
                <InputText onChange={(event) => { setMessageUser(event.currentTarget.value); }} onKeyDown={handleInputChatUser} type="text" className="p-inputtext-md" placeholder="Quanti modelli ha la Ford Focus Mk2" style={{ width: "100%", borderRadius: 0 }} />
                {/* Button for invio */}
                <Button icon="pi pi-send" onClick={handleSendChatUser} style={{ borderRadius: 0, width: "15%" }} />
            </div>
        </div> : <Button onClick={() => setIsOpened(true)} icon="pi pi-comments" size="large" label="Chatbot" />}
    </div>
}

export default Chatbot;
