import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationType, addNotification } from "../redux/actions/notification-actions";
import { setBreadcrumb } from "../redux/actions/breadcrumb-actions";
import { Link, useNavigate } from "react-router-dom";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ApiService from "../api-service";
import { AxiosError } from "axios";
import { AxiosToastError } from "../utils/AxiosToastError";
import { setTokens } from "../redux/actions/auth-actions";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import { Messages } from "primereact/messages";
import { useMountEffect } from 'primereact/hooks';
import { ProgressSpinner } from "primereact/progressspinner";
import { ChartOptions } from "chart.js";

interface HistoricalRevisionsPageProps {

}

export const HistoricalRevisionsPage: FC<HistoricalRevisionsPageProps> = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const msgs: any = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [storico, setStorico] = useState<any[]>([]);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [input, setInput] = useState(''); // State for input value

    useEffect(() => {
        dispatch(
            setBreadcrumb([
                {
                    id: "1",
                    label: "Dashboard",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/dashboard'}>Dashboard</Link>
                },
                {
                    id: "2",
                    label: "Historical Revisions",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/historical-revisions'}>Historical Revisions</Link>
                }
            ])
        );
    }, []);

    const isTampered = (data: any[]) => {
        for (let i = 1; i < data.length; i++) {
            if (data[i].numKmiPcsRvs <= data[i - 1].numKmiPcsRvs) {
                // Se il chilometraggio attuale è minore del chilometraggio precedente
                return true;
            }
        }

        // Se non ci sono stati decrementi, assumiamo che il contachilometri non sia stato manomesso
        return false;
    }

    const handleSearch = () => {
        setLoading(true)
        setStorico([]);
        ApiService.getHistoricalRevisions(input).then(res => {
            res.data.sort((a: any, b: any) => new Date(a.datRvs).getTime() - new Date(b.datRvs).getTime());

            setStorico(res.data);
            setLoading(false)
            dispatch(
                addNotification("Storico revisioni ottenuto con successo!", NotificationType.SUCCESS)
            )

            // Check regolarità storico
            if (msgs.current) {
                msgs.current.clear();
                if (!isTampered(res.data)) {
                    msgs.current.show({ id: '1', sticky: true, severity: 'success', summary: 'Situazione Regolare', detail: 'Kilometraggio regolare', closable: false });
                } else {
                    msgs.current.show({ id: '2', sticky: true, severity: 'error', summary: 'Sitazione NON Regolare', detail: 'Possibilità di kilometraggio alterato!', closable: false });
                }
            }
        }).catch((err) => {
            setLoading(false)
            AxiosToastError.showError(err, dispatch)
        })
    };

    const handleClear = () => {
        setStorico([]);
        setInput("")
        dispatch(
            addNotification("Storico pulito con successo!", NotificationType.WARNING)
        )
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Warn', detail: 'Inserisci la targa e clicca su cerca', closable: false });
        }
    }

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const maxKm = Math.max(...storico.map(s => s.numKmiPcsRvs));
        const minKm = Math.min(...storico.map(s => s.numKmiPcsRvs));
        const margin = (maxKm - minKm) * 0.50;

        const data = {
            labels: storico.map(s => s.datRvs.split(" ")[0]),
            datasets: [
                {
                    label: input,
                    data: storico.map(s => s.numKmiPcsRvs),
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4,
                    pointRadius: 10, // Size of the points here (DOTS SIZE)
                    pointBackgroundColor: documentStyle.getPropertyValue('--cyan-100'),
                    pointBorderColor: documentStyle.getPropertyValue('--cyan-900'),
                }
            ]
        };

        const options: ChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    beginAtZero: false,
                    min: minKm - margin,
                    max: maxKm + margin,
                    ticks: {
                        color: textColorSecondary,
                        callback: function (value: any) {
                            return value.toLocaleString('it-IT') + ' km';
                        }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [storico, input]);



    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Warn', detail: 'Inserisci la targa e clicca su cerca', closable: false });
        }
    });

    return (
        <div>
            <div style={{
                paddingTop: 25,
                paddingBottom: 15,
                display: "flex",
                gap: 5
            }}>
                <InputText value={input} onChange={(e) => setInput(e.target.value.toUpperCase().slice(0, 7))} placeholder="Enter license plate" />
                <Button icon="pi pi-search" label="" onClick={handleSearch} />
                <Button icon="pi pi-trash" severity="danger" label="" onClick={handleClear} />
            </div>

            {loading && <div style={{
                paddingTop: 25,
                paddingBottom: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }} className="card flex justify-content-center">
                <div>
                    <h3 style={{ textAlign: "center" }}>Attendere stiamo cercando le informazioni...</h3>
                    <h5 style={{ textAlign: "center" }}>L'operazione potrebbe richiedere anche alcuni minuti</h5>
                </div>
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>}


            {!loading && <Card title={"Storico Revisione: " + input}>
                {storico.length > 0 ?
                    <div>
                        <Messages ref={msgs} />
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div> :
                    <div className="card flex justify-content-center">
                        <Messages ref={msgs} />
                    </div>}
            </Card>}

        </div>
    );
}