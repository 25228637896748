
import { combineReducers, legacy_createStore as createStore } from 'redux';
import notificationReducer from './reduceres/notifications-reducer';
import breadcrumbReducer from './reduceres/breadcrumb-reducer';
import authReducer from './reduceres/auth-reducer';

const rootReducer = () =>
  combineReducers({
    notificationReducer,
    breadcrumbReducer,
    authReducer
  });

export const store = createStore(
  rootReducer(),
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export type ReduxState = ReturnType<ReturnType<typeof rootReducer>>;
