import { SET_TOKENS, setTokens } from "../actions/auth-actions";
import { jwtDecode } from "jwt-decode";


export interface AuthState {
    accessToken: string;
    refreshToken: string;
    payload: any
}

const initialState: AuthState = {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    payload: Object.keys(JSON.parse(localStorage.getItem("payload") || "{}")).length > 0 ? 
      JSON.parse(localStorage.getItem("payload") || "{}") : undefined
};

type AuthAction = ReturnType<typeof setTokens>;

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case SET_TOKENS:

    if(action.payload.accessToken !== ""){
      localStorage.setItem("accessToken", action.payload.accessToken)
      localStorage.setItem("refreshToken", action.payload.refreshToken)
      localStorage.setItem("payload", JSON.stringify(jwtDecode(action.payload.accessToken)))
    }else{
      localStorage.clear();
    }
    
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        payload: action.payload.accessToken !== "" ? jwtDecode(action.payload.accessToken) : undefined
      }
    default:
      return state;
  }
};

export default authReducer;
