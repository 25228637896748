import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../redux/actions/breadcrumb-actions";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import ApiService from "../../api-service";
import { AxiosToastError } from "../../utils/AxiosToastError";
import { authSelector } from "../../redux/selectors/selectors";
import { NotificationType, addNotification } from "../../redux/actions/notification-actions";
import { setTokens } from "../../redux/actions/auth-actions";

interface UserPageProps {

}

export const UserPage: FC<UserPageProps> = ({ }) => {
    const dispatch = useDispatch();

    const [pdfUrl, setPdfUrl] = useState('');
    const [userCredit, setUserCredit] = useState<string>("0.00");
    const [visiblePrivacy, setVisiblePrivacy] = useState(false);
    const [lightDark, setLightDark] = useState(localStorage.getItem("modalita") || "LIGHT")
    const auth = useSelector(authSelector);

    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [isDisabledUpdateUser, setIsDisabledUpdateUser] = useState(true);

    useEffect(() => {        
        setName(auth?.payload?.name || '');
        setSurname(auth?.payload?.surname || '');
    }, [auth])

    useEffect(() => {
        if (name !== auth?.payload?.name || surname !== auth?.payload?.surname) {
            setIsDisabledUpdateUser(false);
        } else {
            setIsDisabledUpdateUser(true);
        }
    }, [name, surname])

    useEffect(() => {
        ApiService.getUserCredit().then(res => {
            setUserCredit(res.data.credit.toString());
        }).catch(err => AxiosToastError.showError(err, dispatch))
    }, [])

    useEffect(() => {
        dispatch(
            setBreadcrumb([
                {
                    id: "1",
                    label: "Dashboard",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/dashboard'}>Dashboard</Link>
                },
                {
                    id: "2",
                    label: "Login",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/user'}>Profilo</Link>
                }
            ])
        );
    }, [])

    useEffect(() => {
        if (visiblePrivacy && !pdfUrl) {
            ApiService.getPrivacy().then(res => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfUrl(pdfUrl);
            }).catch(err => AxiosToastError.showError(err, dispatch))
        }
    }, [visiblePrivacy])

    const legendTemplate = (
        <div className="flex align-items-center gap-2 px-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" className="mr-2" shape="circle" />
            <span className="font-bold">{auth?.payload?.email.split('@')[0] || 'No user'}</span>
            <Tag value={`${userCredit}€`}></Tag>
        </div>
    );

    const legendTemplateSettings = (
        <div className="flex align-items-center gap-2 px-2">
            <i className="pi pi-spin pi-cog" style={{ fontSize: '1rem' }}></i>
            <span className="font-bold">Impostazioni</span>
        </div>
    )

    const changeModalita = () => {
        const current = localStorage.getItem("modalita") || "LIGHT";
        if (current === "LIGHT") {
            localStorage.setItem("modalita", "DARK")
        } else {
            localStorage.setItem("modalita", "LIGHT")
        }
        window.location.href = "/"
    }

    const handleUpdateUser = () => {
        ApiService.updateUserInfo({
            name,
            surname
        }).then(res => {
            dispatch(
                addNotification(res.data.message, NotificationType.SUCCESS)
            );

            ApiService.refreshToken({
                refreshToken: auth.refreshToken
            }).then(res => {
                dispatch(
                    setTokens(res.data.access_token, res.data.refresh_token)
                )
            }).catch(err => AxiosToastError.showError(err, dispatch))
        }).catch(err => AxiosToastError.showError(err, dispatch))
    }

    return (
        <div>
            <Dialog maximizable header="Privacy" visible={visiblePrivacy} style={{ width: '50vw' }} onHide={() => setVisiblePrivacy(false)}>
                <iframe
                    src={pdfUrl}
                    style={{ width: '100%', height: '100vh' }}
                    frameBorder="0"
                    typeof="application/pdf"
                    title="PDF Viewer"
                ></iframe>
            </Dialog>

            <div className="card" style={{
                marginTop: 25,
            }}>
                <Fieldset legend={legendTemplate}>
                    <div style={{
                        paddingLeft: "25%",
                        paddingRight: "25%"
                    }}>
                        <div style={{
                            justifyContent: "center"
                        }} className="flex flex-row gap-2">
                            <div className="flex flex-column gap-2" style={{
                                width: "100%"
                            }}>
                                <label htmlFor="nome">Nome</label>
                                <IconField iconPosition="left">
                                    <InputIcon className="pi pi-at"> </InputIcon>
                                    <InputText style={{
                                        width: "100%"
                                    }} id="nome"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name} placeholder="Imposta nome" />
                                </IconField>
                            </div>

                            <div className="flex flex-column gap-2" style={{
                                width: "100%"
                            }}>
                                <label htmlFor="cognome">Cognome</label>
                                <IconField iconPosition="left">
                                    <InputIcon className="pi pi-at"> </InputIcon>
                                    <InputText style={{
                                        width: "100%"
                                    }}
                                        onChange={(e) => setSurname(e.target.value)}
                                        id="cognome" value={surname} placeholder="Imposta cognome" />
                                </IconField>
                            </div>
                        </div>

                        <div style={{
                            justifyContent: "center",
                            marginTop: 15
                        }} className="flex flex-row gap-2">
                            <div className="flex flex-column gap-2" style={{
                                width: "100%"
                            }}>
                                <label htmlFor="email">Email</label>
                                <IconField iconPosition="left">
                                    <InputIcon className="pi pi-at"> </InputIcon>
                                    <InputText style={{
                                        width: "100%"
                                    }} id="email" disabled value={auth?.payload?.email || '--'} />
                                </IconField>
                            </div>
                        </div>

                        <div style={{
                            justifyContent: "center",
                            marginTop: 15
                        }} className="flex flex-row gap-2">
                            <Button style={{ width: "100%" }} text label="Reset Password" severity="warning" />
                            <Button onClick={handleUpdateUser} disabled={isDisabledUpdateUser} style={{ width: "100%" }} label="Aggiorna" severity="success" />
                        </div>
                    </div>
                </Fieldset>
            </div>

            <Divider style={{
                marginTop: 10
            }} />

            <div className="card" style={{
                marginBottom: 16
            }}>
                <Fieldset legend={legendTemplateSettings}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Button onClick={changeModalita} icon={`pi ${lightDark === "DARK" ? 'pi-sun' : 'pi-moon'}`} severity="info" outlined />
                        <Button icon="pi pi-info-circle" severity="info" outlined />
                        <Button onClick={() => setVisiblePrivacy(true)} icon="pi pi-lock" severity="info" outlined />
                    </div>
                </Fieldset>
            </div>
        </div>
    )
}