import { AxiosError } from "axios";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from "primereact/fieldset";
import { useMountEffect } from "primereact/hooks";
import { Message } from "primereact/message";
import { Messages } from "primereact/messages";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ApiService from "../api-service";
import { setBreadcrumb } from "../redux/actions/breadcrumb-actions";
import { AxiosToastError } from "../utils/AxiosToastError";
import { useMediaQuery } from 'react-responsive';
import { Divider } from "primereact/divider";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Timeline } from "primereact/timeline";
import { Image } from "primereact/image";
import { NotificationType, addNotification } from "../redux/actions/notification-actions";
import { ApiResponseDTO, CarData } from "../api-service/types";

interface CarPageProps {

}

export const CarPage: FC<CarPageProps> = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        if (!isMobile) {
            dispatch(
                setBreadcrumb([
                    {
                        id: "1",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/dashboard'}>Dashboard</Link>
                    }
                ])
            );
        } else {
            dispatch(
                setBreadcrumb([])
            );
        }

    }, [isMobile])

    const [activeIndex, setActiveIndex] = useState(0);
    const [plate, setPlate] = useState<string>("");
    const [plateData, setPlateData] = useState<CarData | undefined>();

    const items = [
        {
            label: 'Targa/Vin',
        },
        {
            label: 'Analizzo...'
        },
        {
            label: 'Risultati'
        }
    ];

    const checkPlateFormat = (plate: string) => {
        // Definiamo il pattern della targa italiana
        var pattern = /^[A-Z]{2}\d{3}[A-Z]{2}$/;
        return !pattern.test(plate);
    }

    const handleSubmit = () => {
        setActiveIndex(1)

        ApiService.getCarInfo({
            plate: plate,
            full_info: false
        }).then((res) => {
            const apiResponse: ApiResponseDTO<{
                data: CarData
            }> = res.data

            apiResponse.alerts?.forEach((alert) => {
                dispatch(
                    addNotification(alert.message || "", AxiosToastError.getNotificationType(alert.type || "SUCCESS"))
                )
            })
            
            setPlateData(apiResponse.object?.data)
            setActiveIndex(2)
        }).catch((err) => {
            const apiResponse: ApiResponseDTO<{}> = err.response?.data
            
            apiResponse.alerts?.forEach((alert) => {
                dispatch(
                    addNotification(alert.message || "", AxiosToastError.getNotificationType(alert.type || "ERROR"))
                )
            })
            
            setPlateData({})
            setActiveIndex(0)
        })
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (!checkPlateFormat(plate))
                handleSubmit()
        }
    };

    const engine = [
        { status: 'Clindrata', date: plateData?.enginesize || "--", icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Alimentazione', date: plateData?.fueltype || "--", icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'CV', date: plateData?.powercv || "--", icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'KW', date: plateData?.powerkw || "--", icon: 'pi pi-check', color: '#607D8B' }
    ];

    const info = [
        { status: 'Versione', date: plateData?.version || "--", icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Immatricolazione', date: plateData?.registrationyear || "--", icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Marca', date: plateData?.carmake || "--", icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Modello', date: plateData?.carmodel || "--", icon: 'pi pi-check', color: '#607D8B' },
        { status: 'VIN(Cod. telaio)', date: plateData?.vin || "--", icon: 'pi pi-check', color: '#607D8B' },
        { status: 'Porte', date: plateData?.numberofdoors || "--", icon: 'pi pi-check', color: '#607D8B' },
    ];

    const security = [
        { status: 'Immobilizer', date: plateData?.immobiliser || "--", icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'ABS', date: plateData?.abs || "--", icon: 'pi pi-cog', color: '#673AB7' },
    ];

    return (

        <div style={{
            padding: 30
        }} className="card mt-4">
            {activeIndex === 0 && <> <Steps style={{
                paddingBottom: 30
            }} activeIndex={activeIndex} model={items} />

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 4,
                }} className="mt-4">
                    <InputText onKeyPress={handleKeyPress} value={plate} onChange={(e) => setPlate(e.target.value.toUpperCase().slice(0, 7))} invalid={checkPlateFormat(plate)} style={{
                        textAlign: "center",
                    }} type="text" className="p-inputtext-lg" placeholder="Inserici una targa..." />

                    <Button onClick={handleSubmit} severity="success" disabled={checkPlateFormat(plate)} outlined raised icon="pi pi-search"></Button>
                </div></>}

            {activeIndex === 1 && <> <Steps style={{
                paddingBottom: 30
            }} activeIndex={activeIndex} model={items} />

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                    flexDirection: "column"
                }} className="mt-4">
                    <h3>Attendere stiamo analizzando i dati...</h3>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div></>}

            {activeIndex === 2 && <> <Steps style={{
                paddingBottom: 30
            }} activeIndex={activeIndex} model={items} />

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: isMobile ? "column" : "row",
                    gap: 10
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 10
                    }}>
                        <Image style={{
                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                            borderRadius: 10
                        }} src={plateData?.img} alt="Image" width="55" preview />
                        <h2><div style={{
                            fontSize: 14,
                            color: "var(--text-color-secondary)"
                        }}>{plate}</div> {plateData?.version}</h2>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 10
                    }}>
                        <Button style={{
                            marginBottom: isMobile ? 16 : 0
                        }} onClick={() => {
                            navigate("/historical-revisions")
                        }} label="Storico Revisioni" severity="success" text raised />

                        <Button style={{
                            marginBottom: isMobile ? 16 : 0
                        }} onClick={() => {
                            setActiveIndex(0)
                            setPlate("")
                            setPlateData({})
                        }} label="Nuova visura" severity="info" text raised />
                    </div>
                </div>

                <TabView>
                    <TabPanel header="Generali">
                        <Timeline value={info} opposite={(item) => item.status} content={(item) => <small className="text-color-secondary">{item.date}</small>} />
                    </TabPanel>
                    <TabPanel header="Motore">
                        <Timeline value={engine} opposite={(item) => item.status} content={(item) => <small className="text-color-secondary">{item.date}</small>} />
                    </TabPanel>
                    <TabPanel disabled={!plateData?.immobiliser && !plateData?.abs} header="Sicurezza">
                        <Timeline value={security} opposite={(item) => item.status} content={(item) => <small className="text-color-secondary">{item.date}</small>} />
                    </TabPanel>
                </TabView></>}
        </div>
    )
}