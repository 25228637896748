// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 1rem;
}

:root {
  --primary-color: red !important;
}

body {
  margin: 0px;
  height: 100%;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,uCAAuC;EACvC,+BAA+B;EAC/B,mBAAmB;EACnB,wBAAwB;EACxB,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["html {\n  font-size: 1rem;\n}\n\n:root {\n  --primary-color: red !important;\n}\n\nbody {\n  margin: 0px;\n  height: 100%;\n  padding: 2rem;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background-color: var(--surface-ground);\n  font-family: var(--font-family);\n  font-weight: normal;\n  color: var(--text-color);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
