// src/actions/notificationActions.ts

export enum NotificationType {
    SUCCESS,
    WARNING,
    ERROR,
    INFO
  }
  
  export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
  export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
  
  export const addNotification = (message: string, type: NotificationType) => ({
    type: ADD_NOTIFICATION,
    payload: { message, type },
  });
  
  export const removeNotification = (id: number) => ({
    type: REMOVE_NOTIFICATION as typeof REMOVE_NOTIFICATION,
    payload: id,
  });
  