import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../index';
import { NotificationsState } from '../reduceres/notifications-reducer';
import { BreadcrumbState } from '../reduceres/breadcrumb-reducer';
import { AuthState } from '../reduceres/auth-reducer';

export const notificationSelector = createSelector(
  (state: ReduxState): NotificationsState => state.notificationReducer,
  (state: any) => state
);

export const breadcrumbSelector = createSelector(
  (state: ReduxState): BreadcrumbState => state.breadcrumbReducer,
  (state: any) => state
)

export const authSelector = createSelector(
  (state: ReduxState): AuthState => state.authReducer,
  (state: any) => state
)
