// src/components/Notification.tsx

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationType, removeNotification } from '../../redux/actions/notification-actions';
import { notificationSelector } from '../../redux/selectors/selectors';
import { Notification } from './Notification';
import { Toast } from 'primereact/toast';
// import { config } from '../../constants';

const Notifications: React.FC = () => {
  const dispatch = useDispatch();

  const { notifications } = useSelector(notificationSelector);
  const toast: any = useRef(null);

  useEffect(() => {
    notifications.forEach((notification: any) => {
      // Set Timeout For SUCCESS Notification
      if (notification.type === NotificationType.SUCCESS) {
        toast!.current!.show({ severity: 'success', summary: 'Success', detail: notification.message });
      }

      // Set Timeout For ERROR Notification
      if (notification.type === NotificationType.ERROR) {
        toast!.current!.show({ severity: 'error', summary: 'Error', detail: notification.message });
      }

      // Set Timeout For WARNING Notification
      if (notification.type === NotificationType.WARNING) {
        toast!.current!.show({ severity: 'warn', summary: 'Warning', detail: notification.message });
      }

      // Set Timeout For INFO Notification
      if (notification.type === NotificationType.INFO) {
        toast!.current!.show({ severity: 'info', summary: 'Info', detail: notification.message });
      }

      dispatch(removeNotification(notification.id));
    });
  }, [dispatch, notifications]);

  return (
    <Toast ref={toast} />
  );
};

export default Notifications;
