import { AxiosError } from "axios";
import { Dispatch, UnknownAction } from "redux";
import { NotificationType, addNotification } from "../redux/actions/notification-actions";


export class AxiosToastError {
    public static showError(error: AxiosError, dispatch: Dispatch<UnknownAction>){
        let msg = error.message;
        const data: any = error.response?.data
        if(data && data.message){
            msg = data.message;
        }
        
        dispatch(
            addNotification(msg, NotificationType.ERROR)
        );
    }

    public static getNotificationType(alertsEnumType: string): NotificationType {
        let notificationType = NotificationType.SUCCESS;
        if (alertsEnumType === "ERROR") {
            notificationType = NotificationType.ERROR;
        } else if (alertsEnumType === "INFO") {
            notificationType = NotificationType.INFO;
        } else if (alertsEnumType === "WARNING") {
            notificationType = NotificationType.WARNING;
        }

        return notificationType;
    }
}