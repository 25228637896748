import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AxiosError } from "axios";
import { Fieldset } from "primereact/fieldset";
import { Message } from "primereact/message";
import { Chart } from "primereact/chart";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { setBreadcrumb } from "../../redux/actions/breadcrumb-actions";
import ApiService from "../../api-service";
import { AxiosToastError } from "../../utils/AxiosToastError";
import { DateUtils } from "../../utils/DateUtils";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { MeterGroup } from 'primereact/metergroup';
import { useMediaQuery } from "react-responsive";

interface ReportsPlatePageProps {

}

enum PlateReportEnum {
    SUCCESS = "SUCCESS",
    FAILED_EXTERNAL_API = "FAILED_EXTERNAL_API",
    FAILED_GPT = "FAILED_GPT",
    FAILED_GENERIC = "FAILED_GENERIC"
}

export const ReportsPlatePage: FC<ReportsPlatePageProps> = ({ }) => {
    const dispatch = useDispatch();

    const [laoding, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<any[]>([]);
    const [reportsFiltered, setReportsFiltered] = useState<any[]>([]);
    const [selectedReport, setSelectedReport] = useState<any>({});
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<any>({ severity: 'success', code: PlateReportEnum.SUCCESS.toString() });

    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        if (!isMobile) {
            dispatch(
                setBreadcrumb([
                    {
                        id: "1", label: "Dashboard",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/dashboard'}>Dashboard</Link>
                    },
                    {
                        id: "2", label: "Admin",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/plate'}>Admin</Link>
                    },
                    {
                        id: "3", label: "Reports",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/plate'}>Reports</Link>
                    },
                    {
                        id: "3", label: "Plate",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/admin/reports/plate'}>Plate</Link>
                    }
                ])
            );
        } else {
            dispatch(
                setBreadcrumb([
                    { id: "2", label: "Admin", template: () => <Link to='/admin/'><i className="pi pi-lock"></i></Link> },
                    { id: "3", label: "Reports", template: () => <Link to='/admin/reports/plate'><i className="pi pi-list"></i></Link> },
                    { id: "4", label: "Plate", template: () => <Link to='/admin/reports/plate'><i className="pi pi-chart-bar"></i></Link> }
                ])
            );
        }

    }, [])

    useEffect(() => {
        if (Object.keys(selectedReport).length > 0) {
            setViewModal(true);
        }
    }, [selectedReport])

    useEffect(() => {
        setLoading(true)
        setReports([])
        setReportsFiltered([])
        ApiService.getReportsPlate().then(res => {
            let values: any[] = []
            res.data.forEach((r: any) => {
                if (r.status === statusFilter.code) {
                    r.date = DateUtils.timestampFormat(r.date)
                    values.push(r)
                }
            })

            setReports(res.data)
            setReportsFiltered(values)
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            AxiosToastError.showError(err, dispatch)
        })
    }, [statusFilter])

    const calcolaPercentuale = (numero: number) => {
        let percentuale = (numero / reports.length) * 100;
        return Math.round(percentuale);
    }

    const status = [
        { severity: 'success', code: PlateReportEnum.SUCCESS.toString() },
        { severity: 'warning', code: PlateReportEnum.FAILED_EXTERNAL_API.toString() },
        { severity: 'danger', code: PlateReportEnum.FAILED_GPT.toString() },
        { severity: 'info', code: PlateReportEnum.FAILED_GENERIC.toString() },
    ];

    const meterGroupValues = [
        { label: PlateReportEnum.SUCCESS.toString(), color: '#34d399', value: calcolaPercentuale(reports.filter(r => r.status === PlateReportEnum.SUCCESS.toString()).length) },
        { label: PlateReportEnum.FAILED_EXTERNAL_API.toString(), color: '#fbbf24', value: calcolaPercentuale(reports.filter(r => r.status === PlateReportEnum.FAILED_EXTERNAL_API.toString()).length) },
        { label: PlateReportEnum.FAILED_GPT.toString(), color: '#d32f2f', value: calcolaPercentuale(reports.filter(r => r.status === PlateReportEnum.FAILED_GPT.toString()).length) },
        { label: PlateReportEnum.FAILED_GENERIC.toString(), color: '#0288d1', value: calcolaPercentuale(reports.filter(r => r.status === PlateReportEnum.FAILED_GENERIC.toString()).length) }
    ];

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Badge severity={option.severity} value={option.code}></Badge>
                </div>
            );
        }

        return <div className="flex align-items-center">
            <Badge severity="success" value={PlateReportEnum.SUCCESS.toString()}></Badge>
        </div>
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <Badge severity={option.severity} value={option.code}></Badge>
            </div>
        );
    };


    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    return (
        <div>
            <h3>Numero di report raccolti: <b>{reports.length}</b></h3>
            <div style={{
                paddingTop: 5,
                paddingBottom: 15,
                display: "flex",
                flexDirection: "column"
            }} className="card flex justify-content-center">
                <MeterGroup values={meterGroupValues} />
            </div>

            <Dialog header="Stack Trace Error" visible={viewModal} maximizable style={{ width: '50vw', backgroundColor: "var(--gray-900)" }} onHide={() => setViewModal(false)}>
                <p className="m-0">
                    {selectedReport.errorStackTrace}
                </p>
            </Dialog>

            <div style={{
                paddingTop: 25,
                paddingBottom: 15,
                display: "flex",
                flexDirection: "column"
            }}>
                <Dropdown defaultValue={PlateReportEnum.SUCCESS.toString()} value={statusFilter} onChange={(e) => setStatusFilter(e.value)} options={status} optionLabel="name" placeholder="Select a Country"
                    filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="w-full md:w-14rem" />
            </div>

            {statusFilter.code === PlateReportEnum.SUCCESS.toString() && <DataTable stripedRows removableSort sortField="id" sortOrder={-1} value={reportsFiltered} paginator rows={10} tableStyle={{ minWidth: '50rem' }} onSelectionChange={(e) => setSelectedReport(e.value)}
                loading={laoding}
                selectionMode="single"
                isDataSelectable={() => false}
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft}>
                <Column sortable field="plate" header="Targa" style={{ width: '25%' }}></Column>
                <Column header="Status" body={<Badge severity="success" value="SUCCESS"></Badge>}></Column>
                <Column sortable field="date" header="Data" style={{ width: '25%' }}></Column>
                <Column sortable field="emailUser" header="User" style={{ width: '25%' }}></Column>
            </DataTable>}

            {statusFilter.code === PlateReportEnum.FAILED_EXTERNAL_API.toString() && <DataTable stripedRows removableSort sortField="id" sortOrder={-1} value={reportsFiltered} paginator rows={10} tableStyle={{ minWidth: '50rem' }}
                loading={laoding}
                onSelectionChange={(e) => setSelectedReport(e.value)}
                selectionMode="single"
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft}>
                <Column sortable field="plateError" header="Targa" style={{ width: '10%' }}></Column>
                <Column style={{ width: '10%' }} header="Status" body={<Badge severity="warning" value="FAILED_EXTERNAL_API"></Badge>}></Column>
                <Column sortable field="date" header="Data" style={{ width: '10%' }}></Column>
                <Column field="errorMessage" header="Errore" style={{ width: '25%' }}></Column>
                <Column sortable field="emailUser" header="User" style={{ width: '25%' }}></Column>
            </DataTable>}

            {statusFilter.code === PlateReportEnum.FAILED_GPT.toString() && <DataTable stripedRows removableSort sortField="id" sortOrder={-1} value={reportsFiltered} paginator rows={10} tableStyle={{ minWidth: '50rem' }}
                loading={laoding}
                onSelectionChange={(e) => setSelectedReport(e.value)}
                selectionMode="single"
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft}>
                <Column sortable field="plateError" header="Targa" style={{ width: '10%' }}></Column>
                <Column style={{ width: '10%' }} header="Status" body={<Badge severity="danger" value="FAILED_GPT"></Badge>}></Column>
                <Column sortable field="date" header="Data" style={{ width: '10%' }}></Column>
                <Column field="errorMessage" header="Errore" style={{ width: '25%' }}></Column>
                <Column sortable field="emailUser" header="User" style={{ width: '25%' }}></Column>
            </DataTable>}

            {statusFilter.code === PlateReportEnum.FAILED_GENERIC.toString() && <DataTable stripedRows removableSort sortField="id" sortOrder={-1} value={reportsFiltered} paginator rows={10} tableStyle={{ minWidth: '50rem' }}
                loading={laoding}
                onSelectionChange={(e) => setSelectedReport(e.value)}
                selectionMode="single"
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft}>
                <Column sortable field="plateError" header="Targa" style={{ width: '10%' }}></Column>
                <Column style={{ width: '10%' }} header="Status" body={<Badge severity="info" value="FAILED_GENERIC"></Badge>}></Column>
                <Column sortable field="date" header="Data" style={{ width: '10%' }}></Column>
                <Column sortable field="date" header="Data" style={{ width: '10%' }}></Column>
                <Column sortable field="emailUser" header="User" style={{ width: '25%' }}></Column>
            </DataTable>}
        </div>
    )
}