import { AxiosError } from "axios";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from "primereact/fieldset";
import { useMountEffect } from "primereact/hooks";
import { Message } from "primereact/message";
import { Messages } from "primereact/messages";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ApiService from "../api-service";
import { setBreadcrumb } from "../redux/actions/breadcrumb-actions";
import { AxiosToastError } from "../utils/AxiosToastError";
import { useMediaQuery } from 'react-responsive';
import { Divider } from "primereact/divider";

interface DashboardPageProps {

}

export const DashboardPage: FC<DashboardPageProps> = ({ }) => {
    const dispatch = useDispatch();

    const [plates, setPlates] = useState([]);
    const [latestPlate, setLatestPlate] = useState("")
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [laoding, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;

    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        setLoading(true)
        ApiService.getList().then(res => {
            console.log(res.data.list.map((e: any) => e.plate));

            setPlates(res.data.list.map((e: any) => e.plate))
            if (res.data.list.length > 0) {
                setLatestPlate(res.data.list[res.data.list.length - 1].plate.licenseplate)
            } else {
                setLatestPlate("Nessuna visura effettuata")
            }

            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            AxiosToastError.showError(err, dispatch)
        })
    }, []);

    useEffect(() => {
        if (!isMobile) {
            dispatch(
                setBreadcrumb([
                    {
                        id: "1",
                        template: () => <Link style={{
                            textDecoration: "unset",
                            color: "#495057"
                        }} to={'/dashboard'}>Dashboard</Link>
                    }
                ])
            );
        } else {
            dispatch(
                setBreadcrumb([])
            );
        }

    }, [isMobile])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            // labels: ['Visure'],
            datasets: [
                {
                    data: [plates.length],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    const msgs: any = useRef(null);
    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'info', summary: 'App Mobile', detail: 'Scarica la nostra app mobile!', closable: true, });
        }
    });

    return (

        <div>

            <Messages ref={msgs} />

            <Divider />

            <div style={!isMobile ? {
                paddingTop: 25,
                paddingBottom: 25,
                display: "flex",
                justifyContent: "space-between"
            } : {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 16,
                marginBottom: 36
            }}>
                <Fieldset legend="Ultima visura" style={{
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10
                    }}>
                        <Message text={latestPlate} />
                    </div>

                </Fieldset>

                <Fieldset legend="N. Visure Effettuate" style={{
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10
                    }}>
                        <Button type="button" label="N. visure">
                            <Badge value={plates.length}></Badge>
                        </Button>
                    </div>
                </Fieldset>

                <Fieldset legend="Azioni Rapide" style={{
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 10
                    }}>
                        <Button onClick={() => {
                            navigate("/car")
                        }} style={{ width: "100%" }} label="Visura Auto" severity="success" icon="pi pi-car" />
                        <Button onClick={() => {
                            navigate("/historical-revisions")
                        }} style={{ width: "100%" }} label="Storico Revisioni" severity="info" icon="pi pi-shield" />
                    </div>
                </Fieldset>
            </div>
            <Divider />
            <DataTable loading={laoding} stripedRows removableSort sortField="id" sortOrder={-1} value={plates} paginator rows={5} tableStyle={{ minWidth: '50rem' }}
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft}>
                <Column sortable field="licenseplate" header="Targa" style={{ width: '25%' }}></Column>
                <Column sortable field="carmodel" header="Modello" style={{ width: '25%' }}></Column>
                <Column sortable field="carmake" header="Marca" style={{ width: '25%' }}></Column>
                <Column sortable field="powercv" header="Cv" style={{ width: '25%' }}></Column>
            </DataTable>

        </div>
    )
}