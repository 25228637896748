import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationType, addNotification } from "../redux/actions/notification-actions";
import { setBreadcrumb } from "../redux/actions/breadcrumb-actions";
import { Link, useNavigate } from "react-router-dom";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ApiService from "../api-service";
import { AxiosError } from "axios";
import { AxiosToastError } from "../utils/AxiosToastError";
import { setTokens } from "../redux/actions/auth-actions";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Messages } from "primereact/messages";
import { useMountEffect } from "primereact/hooks";
import { Message } from "primereact/message";
import { InputOtp } from 'primereact/inputotp';

interface LoginPageProps {

}

export const LoginPage: FC<LoginPageProps> = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingResetPsw, setLoadingResetPws] = useState(false);
    const [visibleModalResetPsw, setVisibleModalResetPsw] = useState(false)
    const [visibleModalResetPswOTP, setVisibleModalResetPswOTP] = useState(false)
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordReply, setPasswordReply] = useState<string>('');
    const [emailRipristino, setEmailRipristino] = useState('')
    const [passwordRipristino, setPasswordRipristino] = useState('')
    const [otp, setOtp] = useState<string>('');

    const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        dispatch(
            setBreadcrumb([
                {
                    id: "1",
                    label: "Dashboard",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/dashboard'}>Dashboard</Link>
                },
                {
                    id: "2",
                    label: "Login",
                    template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to={'/login'}>Login</Link>
                }
            ])
        );
    }, [])

    const login = () => {
        ApiService.login({
            email,
            password
        }).then(response => {
            dispatch(
                setTokens(response.data.access_token, response.data.refresh_token)
            )
            dispatch(
                addNotification('Login Effettuato, Benvenuto!', NotificationType.SUCCESS)
            );
            navigate("/dashboard")
        }).catch((err: AxiosError) => AxiosToastError.showError(err, dispatch))
    }

    const signUp = () => {
        if(password !== passwordReply){
            dispatch(
                addNotification('Le password non corrispondono!', NotificationType.ERROR)
            );
            return;
        }
        
        ApiService.registration({
            email,
            password
        }).then(response => {
            dispatch(
                addNotification('Registrazione effettuata con successo!', NotificationType.SUCCESS)
            );

            login()
        }).catch((err: AxiosError) => AxiosToastError.showError(err, dispatch))
    }

    const msgs: any = useRef(null);


    const handleResetPassword = () => {
        if (!emailRipristino) {
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Attenzione', detail: 'Inserisci una mail', closable: true });
            }
        } else {
            setLoadingResetPws(true)
            ApiService.resetPassword(emailRipristino).then(res => {
                dispatch(
                    addNotification(res.data.message, NotificationType.SUCCESS)
                )

                setLoadingResetPws(false)
                setVisibleModalResetPsw(false)
                setVisibleModalResetPswOTP(true)
            }).catch(err => {
                setLoadingResetPws(false)
                AxiosToastError.showError(err, dispatch)
            })
        }
    }

    const handleResetPasswordWithOTP = () => {
        if (!passwordRipristino) {
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Attenzione', detail: 'Inserisci una password', closable: true });
            }
        } else if (!otp) {
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Attenzione', detail: 'Inserisci un OTP', closable: true });
            }
        } else {
            setLoadingResetPws(true)
            ApiService.handleResetPassword({
                email: emailRipristino,
                password: passwordRipristino,
                otp: otp
            }).then(res => {
                dispatch(
                    addNotification(res.data.message, NotificationType.SUCCESS)
                )

                setLoadingResetPws(false)
                setVisibleModalResetPsw(false)
                setVisibleModalResetPswOTP(false)
            }).catch(err => {
                setLoadingResetPws(false)
                AxiosToastError.showError(err, dispatch)
            })
        }
    }

    const footerContent = (
        <div>
            <Button label="Annulla" icon="pi pi-times" onClick={() => setVisibleModalResetPsw(false)} className="p-button-text" />
            <Button loading={loadingResetPsw} severity="success" label="Conferma" icon="pi pi-check" onClick={handleResetPassword} autoFocus />
        </div>
    );

    const footerContentOTP = (
        <div>
            <Button label="Annulla" icon="pi pi-times" onClick={() => setVisibleModalResetPswOTP(false)} className="p-button-text" />
            <Button loading={loadingResetPsw} severity="success" label="Conferma" icon="pi pi-check" onClick={handleResetPasswordWithOTP} autoFocus />
        </div>
    );

    const customInput = ({ events, props }: any) => {
        return <><input {...events} {...props} type="text" className="custom-otp-input-sample" />
            {props.id === 2 && <div className="px-3">
                <i className="pi pi-minus" />
            </div>}
        </>
    };

    const resendCode = () => {
        setOtp('')
        handleResetPassword()
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            login(); // assuming login is a function defined somewhere
        }
    };

    const handleResetAll = () => {
        setEmail('')
        setPassword('')
        setPasswordReply('')
        setEmailRipristino('')
        setPasswordRipristino('')
        setOtp('')
    }


    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        }}>

            <Dialog breakpoints={{ '960px': '75vw', '641px': '100vw' }} header="Reset Password" visible={visibleModalResetPsw} style={{ width: '50vw' }} onHide={() => setVisibleModalResetPsw(false)} footer={footerContent}>
                <Message severity="warn" text="Controlla la mail e recuper il codice OTP a 6 cifre" />
                <div style={{
                    marginTop: 35,
                    marginBottom: 25
                }}>
                    <FloatLabel>
                        <InputText id="email-ripristino" value={emailRipristino} onChange={(e) => setEmailRipristino(e.target.value)} />
                        <label htmlFor="email-ripristino">Email</label>
                    </FloatLabel>
                </div>

                <Messages ref={msgs} />
            </Dialog>

            <Dialog breakpoints={{ '960px': '75vw', '641px': '100vw' }} header="Reset Password" visible={visibleModalResetPswOTP} style={{ width: '50vw' }} onHide={() => setVisibleModalResetPswOTP(false)} footer={footerContentOTP}>
                <div style={{
                    marginTop: 35,
                    marginBottom: 25
                }}>
                    <div className="card flex justify-content-center">
                        <style scoped>
                            {`
                    .custom-otp-input-sample {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        appearance: none;
                        text-align: center;
                        transition: all 0.2s;
                        border-radius: 0;
                        border: 1px solid var(--surface-400);
                        background: transparent;
                        outline-offset: -2px;
                        outline-color: transparent;
                        border-right: 0 none;
                        transition: outline-color 0.3s;
                        color: var(--text-color);
                    }

                    .custom-otp-input-sample:focus {
                        outline: 2px solid var(--primary-color);
                    }

                    .custom-otp-input-sample:first-child,
                    .custom-otp-input-sample:nth-child(5) {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    .custom-otp-input-sample:nth-child(3),
                    .custom-otp-input-sample:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        border-right-width: 1px;
                        border-right-style: solid;
                        border-color: var(--surface-400);
                    }
                `}
                        </style>
                        <div className="flex flex-column align-items-center">
                            <p className="font-bold text-xl mb-2">Ripristino Password</p>
                            <p className="text-color-secondary block mb-5">Controlla nella mail.</p>
                            <div className="mb-3" style={{ width: "100%" }}>
                                <FloatLabel>
                                    <InputText type="password" style={{ width: "100%" }} id="password-ripristino" value={passwordRipristino} onChange={(e) => setPasswordRipristino(e.target.value)} />
                                    <label htmlFor="password-ripristino">Nuova Password</label>
                                </FloatLabel>
                            </div>
                            <InputOtp value={otp} onChange={(e) => setOtp(typeof e.value === "string" ? e.value : '')} length={6} inputTemplate={customInput} style={{ gap: 0 }} />
                            <div className="flex justify-content-between mt-5 align-self-stretch">
                                <Button onClick={resendCode} style={{ width: "100%" }} label="Resend Code" link className="p-0"></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Messages ref={msgs} />
            </Dialog>

            {isLogin ? <div style={{ width: "100%" }} className="card">
                <div className="flex flex-column md:flex-row">
                    <div className="w-full md:w-5 flex flex-column align-items-center justify-content-center gap-3 py-5">
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Email</label>
                            <InputText onChange={(e) => setEmail(e.target.value)} type="text" className="w-12rem" />
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Password</label>
                            <InputText onKeyPress={handleKeyPress} onChange={(e) => setPassword(e.target.value)} type="password" className="w-12rem" />
                        </div>
                        <Button onClick={() => setVisibleModalResetPsw(true)} label="Reset password" severity="warning" text />
                        <Button onClick={login} label="Login" icon="pi pi-user" className="w-10rem mx-auto"></Button>
                    </div>
                    <div className="w-full md:w-2">
                        <Divider layout="vertical" className="hidden md:flex">
                            <b>OR</b>
                        </Divider>
                        <Divider layout="horizontal" className="flex md:hidden" align="center">
                            <b>OR</b>
                        </Divider>
                    </div>
                    <div className="w-full md:w-5 flex align-items-center justify-content-center py-5">
                        <Button onClick={() => { 
                            handleResetAll()
                            setIsLogin(false) 
                        }} label="Sign Up" icon="pi pi-user-plus" severity="success" className="w-10rem"></Button>
                    </div>
                </div>
            </div> : <div style={{ width: "100%" }} className="card">
                <div className="flex flex-column md:flex-row">
                    <div className="w-full md:w-5 flex align-items-center justify-content-center py-5">
                        <Button onClick={() => {
                            handleResetAll()
                            setIsLogin(true) 
                        }} label="Login" icon="pi pi-user-plus" severity="success" className="w-10rem"></Button>
                    </div>
                    <div className="w-full md:w-2">
                        <Divider layout="vertical" className="hidden md:flex">
                            <b>OR</b>
                        </Divider>
                        <Divider layout="horizontal" className="flex md:hidden" align="center">
                            <b>OR</b>
                        </Divider>
                    </div>
                    <div className="w-full md:w-5 flex flex-column align-items-center justify-content-center gap-3 py-5">
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Email</label>
                            <InputText onChange={(e) => setEmail(e.target.value)} type="text" className="w-12rem" />
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Password</label>
                            <InputText onChange={(e) => setPassword(e.target.value)} type="password" className="w-12rem" />
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Ripeti password</label>
                            <InputText onChange={(e) => setPasswordReply(e.target.value)} type="password" className="w-12rem" />
                        </div>
                        <Button onClick={signUp} label="Registrati" icon="pi pi-user" className="w-10rem mx-auto"></Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}