import { MenuItem } from "primereact/menuitem";
import { SET_BREADCRUMB, setBreadcrumb } from "../actions/breadcrumb-actions";


export interface BreadcrumbState {
    breadcrumbs: MenuItem[] | undefined;
}

const initialState: BreadcrumbState = {
    breadcrumbs: [],
};

type BreadcrumbAction = ReturnType<typeof setBreadcrumb>;

const breadcrumbReducer = (state: BreadcrumbState = initialState, action: BreadcrumbAction): BreadcrumbState => {
  switch (action.type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: [
          ...action.payload.breadcrumbs !== undefined ? action.payload.breadcrumbs : []
        ],
      };
    default:
      return state;
  }
};

export default breadcrumbReducer;
