const backEnd = {
    host: window.location.href.includes("demo") ? 'demo.targa.site' : 'api.targa.site',
    useSSL: true
}
const backEndBaseURL = `${backEnd.useSSL ? 'https': 'http'}://${backEnd.host}`  

const notification = {
    notificationSuccessTimeout: 2500,
    notificationErrorTimeout: 5000,
    notificationWaringTimeout: 3000,
    notificationInfoTimeout: 3000,
}

export const config = {
    ...notification,
    backEndBaseURL
}