import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AxiosError } from "axios";
import { Fieldset } from "primereact/fieldset";
import { Message } from "primereact/message";
import { Chart } from "primereact/chart";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { setBreadcrumb } from "../../redux/actions/breadcrumb-actions";
import ApiService from "../../api-service";
import { AxiosToastError } from "../../utils/AxiosToastError";
import { DateUtils } from "../../utils/DateUtils";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { MeterGroup } from 'primereact/metergroup';
import { useMediaQuery } from "react-responsive";

interface UsersPageProps {

}

export const UsersPage: FC<UsersPageProps> = () => {
    const dispatch = useDispatch();
    const [laoding, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any[]>([])
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        if (!isMobile) {
            dispatch(setBreadcrumb([
                {
                    id: "1", label: "Dashboard", template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to='/dashboard'>Dashboard</Link>
                },
                {
                    id: "2", label: "Admin", template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to='/admin/'>Admin</Link>
                },
                {
                    id: "3", label: "Users", template: () => <Link style={{
                        textDecoration: "unset",
                        color: "#495057"
                    }} to='/admin/users'>Users</Link>
                }
            ]));
        } else {
            dispatch(setBreadcrumb([
                { id: "2", label: "Admin", template: () => <Link to='/admin/'><i className="pi pi-lock"></i></Link> },
                { id: "3", label: "Users", template: () => <Link to='/admin/users'><i className="pi pi-users"></i></Link> }
            ]));
        }

    }, [isMobile]);

    useEffect(() => {
        setLoading(true);
        ApiService.getUsers().then(res => {
            setUsers(res.data);
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            AxiosToastError.showError(err, dispatch)
        });
    }, []);

    const adminBadgeBodyTemplate = (rowData: any) => {
        if (rowData.isAdmin === true) {
            return <Badge value="Admin" severity="success" />;
        } else if (rowData.isAdmin === false) {
            return <Badge value="User" severity="info" />;
        } else {
            return <Badge value="User" severity="info" />;
        }
    };

    return (
        <div>
            <h3>Numero di utenti: <b>{users.length}</b></h3>
            <DataTable loading={laoding} stripedRows removableSort sortField="id" sortOrder={-1} value={users} paginator rows={5} tableStyle={{ minWidth: '50rem' }}
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}">
                <Column field="id" header="ID" sortable style={{ width: '5%' }}></Column>
                <Column field="email" header="Email" sortable style={{ width: '70%' }}></Column>
                <Column field="credit" header="Credito" sortable style={{ width: '25%' }}></Column>
                <Column field="isAdmin" header="Admin Status" body={adminBadgeBodyTemplate} sortable style={{ width: '25%' }}></Column>
            </DataTable>
        </div>
    );
}
