import { addNotification, removeNotification, ADD_NOTIFICATION, REMOVE_NOTIFICATION, NotificationType } from '../actions/notification-actions';

export interface Notification {
  id: number;
  message: string;
  type: NotificationType;
}

export interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

type NotificationAction = ReturnType<typeof addNotification | typeof removeNotification>;

const notificationsReducer = (state: NotificationsState = initialState, action: NotificationAction): NotificationsState => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: Date.now(),
            message: action.payload.message,
            type: action.payload.type,
          },
        ],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
